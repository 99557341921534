import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BranchService } from 'src/app/shared/services/branch.service';
import { ModelService } from 'src/app/webview/components/model/model.service';

@Component({
  selector: 'app-sub-branch',
  templateUrl: './sub-branch.component.html',
  styleUrls: ['./sub-branch.component.css']
})
export class SubBranchComponent implements OnInit {

  myform:FormGroup;
  main_branch_list = [];

  constructor(private fb:FormBuilder,
              private router:Router,
              private _flashMessageService:FlashMessagesService,
              private modelservice:ModelService,
              private bservice:BranchService) { }

  ngOnInit(): void {

    this.bservice.getBranchLists()
                 .subscribe((res:any)=>{
                   this.main_branch_list = res.blist;
                 });
                 this.myform = this.fb.group({
                  'inputSBranchName':this.fb.control('',[Validators.required]),
                  'inputSBranchCode':this.fb.control('',[Validators.required]),
                  'inputBranch':this.fb.control('',[Validators.required]),
                  
                })
  }

  onFormSubmit(){
    console.log(this.myform.value);
    this.bservice.addNewSubBranch(this.myform.value)
                 .subscribe((resultdata)=>{
                  
                  this.modelservice.displayDialog('Alert Message', "New Sub Branch Has Been Added");
                  this.router.navigate(['list-sub-branches']);
                },
                error=>{
                  console.log(error.error.message);
                  this._flashMessageService.grayOut(true);
                  this.modelservice.displayDialog('Alert Message', error.error.message);
                });
  }

}
