import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsersService } from 'src/app/shared/services/users.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

   constructor(private userservice: UsersService) {}

   intercept(req: HttpRequest<any>, next: HttpHandler) {
     const authToken = this.userservice.getToken();
     const authReq = req.clone({
       headers : req.headers.set('Authorization', 'Bearer ' + authToken)
     });
     //console.log(authReq);
     return next.handle(authReq);
   }
}