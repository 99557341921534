import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { DropDownEditor } from './shared/aggridcomponents/drop.down.editor';
import { SharedFormModule } from './shared/modules/shared-form/shared-form.module';
import { FooterComponent } from './webview/components/footer/footer.component';
import { PreloaderComponent } from './webview/components/preloader/preloader.component';
import { SideBarComponent } from './webview/components/side-bar/side-bar.component';
import { TopBarComponent } from './webview/components/top-bar/top-bar.component';
import { NumericEditor } from './webview/components/validators-error-handlers/numeric-editor/numeric-editor.component';
import { AddBranchComponent } from './webview/pages/branches/add-branch/add-branch.component';
import { ListBranchesComponent } from './webview/pages/branches/list-branches/list-branches.component';
import { HomePageComponent } from './webview/pages/home-page/home-page.component';
import { AddUserComponent } from './webview/pages/users/add-user/add-user.component';
import { WebLoginPageComponent } from './webview/pages/web-login-page/web-login-page.component';
import { ExportPdfComponent } from './webview/pages/export-pdf/export-pdf.component';
import { SubBranchComponent } from './webview/pages/branches/sub-branch/sub-branch.component';
import { ListSubBranchesComponent } from './webview/pages/branches/list-sub-branches/list-sub-branches.component';
import { ViewBranchComponent } from './webview/pages/branches/view-branch/view-branch.component';
import { ViewSubBranchComponent } from './webview/pages/branches/view-sub-branch/view-sub-branch.component';
import { ListUsersComponent } from './webview/pages/users/list-users/list-users.component';
import { ViewUserComponent } from './webview/pages/users/view-user/view-user.component';
import { AddStudentComponent } from './webview/pages/students/add-student/add-student.component';
import { ViewStudentComponent } from './webview/pages/students/view-student/view-student.component';
import { ListStudentsComponent } from './webview/pages/students/list-students/list-students.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angular2-qrcode';
import { QrcodeImageRenderComponent } from './webview/pages/students/qrcode-image-render/qrcode-image-render.component';
import { LoginComponent } from './webview/pages/login/login.component';
import { ListSevaUsersComponent } from './webview/pages/users/list-seva-users/list-seva-users.component';
import { AuthInterceptor } from './webview/Auth/auth-interceptors';
import { AuthGuard } from './webview/Auth/auth-guard';
import { UserCountComponent } from './webview/pages/users/user-count/user-count.component';
import { UpdateStudentYearComponent } from './webview/pages/students/update-student-year/update-student-year.component';
import { DashboardComponent } from './webview/pages/dashboard/dashboard.component';
import { AddCityComponent } from './webview/pages/extras/add-city/add-city.component';



@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    TopBarComponent,
    SideBarComponent,
    FooterComponent,
    PreloaderComponent,
    AddBranchComponent,
    AddUserComponent,
    HomePageComponent,
    WebLoginPageComponent,
    ListBranchesComponent,
    NumericEditor,
    DropDownEditor,
    ExportPdfComponent,
    SubBranchComponent,
    ListSubBranchesComponent,
    ViewBranchComponent,
    ViewSubBranchComponent,
    ListUsersComponent,
    ViewUserComponent,
    AddStudentComponent,
    ViewStudentComponent,
    ListStudentsComponent,
    QrcodeImageRenderComponent,
    LoginComponent,
    ListSevaUsersComponent,
    UserCountComponent,
    UpdateStudentYearComponent,
    DashboardComponent,
    AddCityComponent,
    
  ],
  imports: [
    BrowserModule,
    SharedFormModule,
    AppRoutingModule,
    HttpClientModule,
    FlashMessagesModule.forRoot(),
    AgGridModule.withComponents([
      NumericEditor,
      DropDownEditor
    ]),
    NgbModule,
    QRCodeModule,
    
  ],
  providers: [{provide:HTTP_INTERCEPTORS,useClass:AuthInterceptor,multi:true},AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
