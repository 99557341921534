import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { UsersService } from 'src/app/shared/services/users.service';
import { ModelService } from 'src/app/webview/components/model/model.service';

@Component({
  selector: 'app-user-count',
  templateUrl: './user-count.component.html',
  styleUrls: ['./user-count.component.css']
})
export class UserCountComponent implements OnInit {

  myform:FormGroup;
  model: NgbDateStruct;

  constructor(private fb:FormBuilder,
    private router:Router,
    private _flashMessageService:FlashMessagesService,
    private userService:UsersService,
    private modelservice:ModelService,) { }

  ngOnInit(): void {
    this.myform = this.fb.group({
      'inputDate':this.fb.control('',[Validators.required]),
      'inputNumber':this.fb.control('',[Validators.required]),
  
    })
  }

  onFormSubmit(){
    console.log(this.myform.value);
    this.userService.updateUserCount(this.myform.value)
                 .subscribe((resultdata)=>{
                  
                  this.modelservice.displayDialog('Alert Message', "User Count Updated!");
                 // this.router.navigate(['list-students']);
                },
                error=>{
                  console.log(error.error.message);
                  this._flashMessageService.grayOut(true);
                  this.modelservice.displayDialog('Alert Message', error.error.message);
                });
  }

}
