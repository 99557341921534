import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router:Router,@Inject(DOCUMENT) private document: Document,) { }

  ngOnInit(): void {

  }

  loadScript(styleName: string) {
    const bhead = this.document.getElementsByTagName('body')[0];
    const script = this.document.createElement('script');
       script.type = 'text/javascript';
       script.src = `${styleName}`;
       script.async = false;
       script.charset = 'utf-8';
       bhead.appendChild(script);
    
  }

  ngAfterViewInit() {
    console.log('cme here in dashboard');
    //this.loadScript('adminlte.js');
    
  }

}
