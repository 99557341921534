import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { AgEditorComponent } from '@ag-grid-community/angular';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;

@Component({
  selector: 'numeric-cell',
  template: `<input
    #input
    (keydown)="onKeyDown($event)"
    [(ngModel)]="value"
    style="width: 100%"
  />`,
})
export class NumericEditor implements AgEditorComponent, AfterViewInit {
  private params: any;
  public value: number;
  public highlightAllOnFocus: boolean = true;
  private cancelBeforeStart: boolean = false;

  @ViewChild('input', { read: ViewContainerRef }) public input: any;

  agInit(params: any): void {
      this.params = params;
      this.value = this.params.value;

      // only start edit if key pressed is a number, not a letter
      this.cancelBeforeStart = params.charPress && (('1234567890'.indexOf(params.charPress) < 0));
  }

  getValue(): any {
      return this.value;
  }

  isCancelBeforeStart(): boolean {
      return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
      return this.value > 1000000;
  };

  onKeyDown(event): void {
      if (!this.isKeyPressedNumeric(event)) {
          if (event.preventDefault) event.preventDefault();
      }
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
      this.input.element.nativeElement.focus();
  }

  private getCharCodeFromEvent(event): any {
      event = event || window.event;
      return (typeof event.which == "undefined") ? event.keyCode : event.which;
  }

  private isCharNumeric(charStr): boolean {
      return !!/\d/.test(charStr) || charStr === 'Backspace';
  }

  private isKeyPressedNumeric(event): boolean {
      const charCode = this.getCharCodeFromEvent(event);
      const charStr = event.key ? event.key : String.fromCharCode(charCode);
      return this.isCharNumeric(charStr);
  }
  
} 