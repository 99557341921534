import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BranchService } from 'src/app/shared/services/branch.service';
import { CitiesService } from 'src/app/shared/services/cities.service';
import { StatesService } from 'src/app/shared/services/states.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { ModelService } from 'src/app/webview/components/model/model.service';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { StudentsService } from 'src/app/shared/services/students.service';


@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.css']
})
export class AddStudentComponent implements OnInit {

  myform:FormGroup;

  city_list:any = [];
  state_list:any = [];
  branch_list:any = [];
  model: NgbDateStruct;
  sub_branch_list:any = [];
  selected_branch_id:any;

  constructor(private fb:FormBuilder,
    private router:Router,
    private _flashMessageService:FlashMessagesService,
    private modelservice:ModelService,
    private bservice:BranchService,
    private stateService:StatesService,
    private cityService:CitiesService,
    private studentService:StudentsService) { }

  ngOnInit(): void {
    this.myform = this.fb.group({
      'inputFirstName':this.fb.control('',[Validators.required]),
      'inputLastName':this.fb.control('',[Validators.required]),
      'inputGender':this.fb.control('',[Validators.required]),
      'inputContact':this.fb.control('',[Validators.required]),
      'inputDOB':this.fb.control('',[Validators.required]),
      'inputClass':this.fb.control('',[Validators.required]),
      'inputBranch':this.fb.control('',[Validators.required]),
      'inputSubBranch':this.fb.control(0,[Validators.required]),
      'inputBalopasanaBookNo':this.fb.control(0,[Validators.required]),
      'inputYear':this.fb.control('', [Validators.required]),
      'inputState': this.fb.control('', [Validators.required]),
      'inputCity': this.fb.control('', [Validators.required]),
      'inputAddress': this.fb.control('', [Validators.required]),
    })
    this.bservice.getBranchLists()
                 .subscribe((res:any)=>{
                    this.branch_list = res.blist;
                 })
    this.stateService.getStatesList()
                    .subscribe((res:any)=>{
                      this.state_list = res.slist;
                    })

  }

  updateSubBranchList(event){
    this.selected_branch_id = event;
    this.sub_branch_list = this.bservice.getSubBranchListsForBranch(this.selected_branch_id)
                                        .subscribe((res:any)=>{
                                          console.log(res);
                                          this.sub_branch_list = res.sblist;
                                        })
  }

  updateCityList(event){
    this.cityService.getStateBasedCityList(event)
                    .subscribe((res:any)=>{
                      this.city_list = res.clist;
                    })
  }

  onFormSubmit(){
    //console.log(this.myform.value);
    this.studentService.addNewStudent(this.myform.value)
                 .subscribe((resultdata)=>{
                  
                  this.modelservice.displayDialog('Alert Message', "New Student Has Been Added");
                  this.router.navigate(['list-students']);
                },
                error=>{
                  console.log(error.error.message);
                  this._flashMessageService.grayOut(true);
                  this.modelservice.displayDialog('Alert Message', error.error.message);
                });
  }

}
