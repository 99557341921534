<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <flash-messages></flash-messages>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Sub Branch Details</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Add Sub Details</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
  
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- left column -->
          <div class="col-md-12">
            <!-- jquery validation -->
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Add Sub Branch</h3>
              </div>
              <!-- /.card-header -->
  
  
              <div class="card-body">
                <!-- form start -->
                <form id="userForm" method="POST" [formGroup]="myform" (submit)="onFormSubmit()">
                  <div class="form-group">
                    <label for="inputBranchName">Enter SubBranch Name</label>
                    <input type="text" name="inputSBranchName"
                      [ngClass]="myform.controls.inputSBranchName.touched&&myform.controls.inputSBranchName.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputSBranchName" placeholder="Enter SubBranch Name">
                    <app-show-errors [control]="myform.controls.inputSBranchName"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputBranchCode">Enter SubBranch Code</label>
                    <input type="text" name="inputBranchCode"
                      [ngClass]="myform.controls.inputSBranchCode.touched&&myform.controls.inputSBranchCode.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputSBranchCode" placeholder="Enter Branch Code">
                    <app-show-errors [control]="myform.controls.inputSBranchCode"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputBranch">Select MainBranch</label>
                    <select class="form-control"
                      [ngClass]="myform.controls.inputBranch.touched&&myform.controls.inputBranch.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputBranch">
                      <option *ngFor="let eachbranch of main_branch_list" value="{{eachbranch.id}}">{{eachbranch.bname}}</option>
                      
                    </select>
                    <app-show-errors [control]="myform.controls.inputBranch"></app-show-errors>
                  </div>
          
                 
                  <!-- /.card-body -->
                  <div class="card-footer p-0">
                    <button type="submit" [disabled]="myform.invalid" class="btn btn-primary">Update SubBranch</button>
                  </div>
                </form>
              </div>
  
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
          <!-- right column -->
          <div class="col-md-6">
  
          </div>
          <!--/.col (right) -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  
  </div>