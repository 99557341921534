import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayErrorsComponent } from './display-errors/display-errors.component';



@NgModule({
  declarations: [
    DisplayErrorsComponent
  ],
  imports: [
    CommonModule
  ],
  exports :[
    DisplayErrorsComponent
  ]
})
export class ValidationErrorModule { }
