import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BranchService } from 'src/app/shared/services/branch.service';
import { CitiesService } from 'src/app/shared/services/cities.service';
import { ModelService } from 'src/app/webview/components/model/model.service';

@Component({
  selector: 'app-add-city',
  templateUrl: './add-city.component.html',
  styleUrls: ['./add-city.component.css']
})
export class AddCityComponent implements OnInit {

  myform:FormGroup;
  state_list = [];
  constructor(private fb:FormBuilder,
    private router:Router,
    private _flashMessageService:FlashMessagesService,
    private modelservice:ModelService,
    private cityService:CitiesService,
    private bservice:BranchService) { }

  ngOnInit(): void {
    this.bservice.getBranchLists()
                 .subscribe((res:any)=>{
                   console.log(res);
                   this.state_list = res.slist;
                 });
                 this.myform = this.fb.group({
                  'inputCity':this.fb.control('',[Validators.required]),
                  'inputState':this.fb.control('',[Validators.required]),
                  
                  
                })
  }

  onFormSubmit(){
    console.log(this.myform.value);
    this.cityService.addNewCity(this.myform.value)
                 .subscribe((resultdata)=>{
                  
                  this.modelservice.displayDialog('Alert Message', "New City Has Been Added");
                  this.router.navigate(['dashboard']);
                },
                error=>{
                  console.log(error.error.message);
                  this._flashMessageService.grayOut(true);
                  this.modelservice.displayDialog('Alert Message', error.error.message);
                });
  }

}
