import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ModelService } from 'src/app/webview/components/model/model.service';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private httpc:HttpClient,
              private globalService:GlobalService,
   ) { 

              }
            
  addNewBranch(formdata){
    return this.httpc.post(this.globalService.BranchApi,formdata);
              
  }

  

  getBranchLists(){
    return this.httpc.get(this.globalService.BranchApi);
                    
  }

  getBranchDetails(bid){
    return this.httpc.get(this.globalService.BranchApi+"/"+bid);
  }

  updateBranchDetails(bid,formdata){
    return this.httpc.put(this.globalService.BranchApi+"/"+bid,formdata);
  }

  deleteBranch(bid){
    return this.httpc.delete(this.globalService.BranchApi+"/"+bid);
              
  }

  addNewSubBranch(formdata){
    return this.httpc.post(this.globalService.SubBranchApi,formdata);
  }

  getSubBranchLists(){
    return this.httpc.get(this.globalService.SubBranchApi);
                    
  }

  getSubBranchListsForBranch(branch_id){
    return this.httpc.get(this.globalService.SubBranchApi+"/forbranch/"+branch_id);
                    
  }

  getSubBranchDetails(sbid){
    return this.httpc.get(this.globalService.SubBranchApi+"/"+sbid);
  }

  updateSubBranchDetails(sbid,formdata){
    return this.httpc.put(this.globalService.SubBranchApi+"/"+sbid,formdata);
  }

  deleteSubBranch(sbid){
    return this.httpc.delete(this.globalService.SubBranchApi+"/"+sbid);
  }

}
