import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { FlashMessagesService } from 'angular2-flash-messages';
import { UsersService } from 'src/app/shared/services/users.service';
import { ModelService } from 'src/app/webview/components/model/model.service';
import { NumericEditor } from 'src/app/webview/components/validators-error-handlers/numeric-editor/numeric-editor.component';


@Component({
  selector: 'app-list-seva-users',
  templateUrl: './list-seva-users.component.html',
  styleUrls: ['./list-seva-users.component.css']
})
export class ListSevaUsersComponent implements OnInit {

  @ViewChild('agGrid') agGrid: AgGridAngular;
  @ViewChild('fitscreen') fitscreen: any;

  public frameworkComponents;
  private gridApi;
  private gridColumnApi;


  private pgridApi;
  private pgridColumnApi;
  
 

  is_print_view:boolean = false;

  public columnList: any = ['FName','LName','Gender','Email','Contact','Role','Address'];
  public printColumnList: any = ['FName','LName','Gender','Email','Contact','Role'];
  plan_table_coloumns: any = [];
  print_plan_table_coloumns: any = [];
  plantableData: any = []; 
  user_roles = {
    "0" : "Administrator",
    "1":"Sub-Admin",
    "2":"User",
    "3":"Sevajan"
    
  }
  users_list:any = [];

 
  
  isDataFetched: boolean = false;
  constructor(private uservice:UsersService,
              private router:Router,
              private _flashMessageService:FlashMessagesService,
              private modelservice:ModelService) { }



  lookupKey(mappings, name) {
    for (var key in mappings) {
        if (mappings.hasOwnProperty(key)) {
            if (name === mappings[key]) {
                return key;
            }
        }
    }
}

setColoumnStrcutureForView(){
  this.plan_table_coloumns = [];
  this.columnList.forEach(element => {
    let each_col = {
      headerName: element,
      //type:(key=="% Completed")?'number':'normal',
      field: element,
      resizable: true,
      lockPosition: false,//disable drag option of coloumns
      sortable: true,
      //filter: true,
     // editable: true,
      hide: (element == "id") ? true : false
    }
    if(element=="Contact")  {
      each_col['cellEditor'] = 'numericEditor'
      each_col['width'] = 100;
    }
    if(element=="Role")  {
      each_col['cellEditor'] = 'select',
      each_col['cellEditorParams'] = {
        values:  Object.keys(this.user_roles)
      },
      each_col['valueFormatter'] = (params) =>{
         return this.user_roles[params.value];
      },
     each_col['valueParser'] = (params) =>{
        return this.lookupKey(this.user_roles, params.newValue);
     }

    }
    if(element=="Address"){
      each_col['cellStyle'] = { 'white-space': 'normal' }
      each_col['wrapText'] = true
      each_col['autoHeight'] = true
    }
    each_col['filter'] = true;
    this.plan_table_coloumns.push(each_col);
  });

  this.plan_table_coloumns.push({
    headerName: "Actions",
    minWidth: 150,
    cellRenderer: this.actionCellRenderer,
    editable: false,
    colId: "actions"
  });
}

getUserList(){
  this.isDataFetched = false;
  this.plantableData = [];
  this.uservice.getSevaUserLists()
  .subscribe((res:any)=>{
   //console.log(res);
   this.users_list = res.ulist;
   //console.log(this.state_list_data);
   //console.log(this.city_list_data);
   this.setColoumnStrcutureForView();
   res.ulist.forEach(eachrow => {
     let each_data ={
       'id':eachrow.id,
       'FName':eachrow.fname,
       'LName':eachrow.lname,
       'Gender':eachrow.gender,
       'Contact':eachrow.contact,
       'Email':eachrow.emailid,
       'Role':this.user_roles[eachrow.role],
       'Address':eachrow.address
     }
     this.plantableData.push(each_data);
   });
   this.isDataFetched =true;
   console.log(this.plantableData);
  })
}


ngOnInit(): void {
    
  this.frameworkComponents = {
    numericEditor: NumericEditor,   
  };
  
  this.getUserList();

  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)){
    this.isDesktopChrome = false;
  }
  else if(/Chrome/i.test(navigator.userAgent)){
    this.isDesktopChrome = true;
  }
  
}

isFitToScreenSet = false;
  
  fittoscreen(event){
    event.preventDefault();
    if (this.isFitToScreenSet) {
      var allColumnIds = [];
      this.agGrid.columnApi.getAllColumns().forEach((column: any) => {
        allColumnIds.push(column.colId);
      });
      
      this.agGrid.columnApi.autoSizeColumns(allColumnIds, false);
      this.isFitToScreenSet = false;
    } else {
      this.agGrid.api.sizeColumnsToFit();
      this.isFitToScreenSet = true;
      
    }
  }
  actionCellRenderer(params) {
    let eGui = document.createElement('div');
    eGui.innerHTML = `
          <button  
            class="btn btn-success"
            data-action="view">
                 View  
          </button>
          <button  
          class="btn btn-danger"
          data-action="delete">
               Delete  
          </button>
          `;
    return eGui;
  }
  onGridReady(params) {
    console.log(params);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    
  }

   //AGGrid Table cell on clicked
   onCellClicked(event) {
    console.log("Cell Clicked");
    console.log(event.data.id);
    //this.temp_row_data_holder = event.data;
    let action = event.event.target.dataset.action;
    if (action === "delete" || action === "view") {
      if (action === "delete") {
        console.log("its delete operation for id : " + event.data.id);
        if(confirm("Are you sure to Delete ")) {
          this.uservice.deleteUser(event.data.id)
                       .subscribe(res=>{
                        this._flashMessageService.show('User Deleted Successfully', { cssClass: 'alert-danger', timeout: 5000 });
                        this.modelservice.displayDialog('Alert Message', "User Has Been Deleted");
                        this.getUserList();
                      })
          
        }
      }

      if (action === "view") {
       this.router.navigate(['view-user',event.data.id]);
      }

    }
  }

  onRowEditingStopped(event){
    console.log("editing stopped");
    console.log(event.data);
    // console.log(event.data.Owner);
    let edited_id = event.data.id;
    const formdata = {
      'inputFirstName':event.data.FName,
      'inputLastName':event.data.LName,
      'inputGender':event.data.Gender,
      'inputContact':event.data.Contact,
      'inputRole':event.data.Role,
      'inputEmail':event.data.Email,
      'inputAddress':event.data.Address,
    }
    this.uservice.updateUserDetails(edited_id,formdata)
                 .subscribe((resultdata)=>{
                  
                  this.modelservice.displayDialog('Alert Message', "User Details Updated!");
                  
                },
                error=>{
                  console.log(error.error.message);
                  this._flashMessageService.grayOut(true);
                  this.modelservice.displayDialog('Alert Message', error.error.message);
                });
  }

  onRowEditingStarted(event){
    console.log("Editing Started");
  }

  exportPdf(is_done:boolean){
    this.is_print_view = true;
    this.printColumnList.forEach(element => {
      let each_col = {
        headerName: element,
        //type:(key=="% Completed")?'number':'normal',
        field: element,
        resizable: true,
        lockPosition: false,//disable drag option of coloumns
        sortable: true,
        //filter: true,
        width:150,
        editable: true,
        hide: (element == "id") ? true : false
      }
      each_col['filter'] = true;
      this.print_plan_table_coloumns.push(each_col);
    });
     
    this.gridApi.setColumnDefs(this.print_plan_table_coloumns);
   // console.log(this.gridApi.getSortModel());
   // console.log(this.gridApi.getFilterModel());
  
    this.gridColumnApi.setColumnState(this.gridColumnApi.getColumnState());
    this.gridApi.setSortModel(this.gridApi.getSortModel());
    this.gridApi.setFilterModel(this.gridApi.getFilterModel());
    this.gridApi.sizeColumnsToFit();
    // call setDomLayout('print') for proper print layout (Api function provided by ag grid)
    this.gridApi.setDomLayout('print');
    // window.blur();
    
    setTimeout(()=>{
      // call print after a time out so that print layout loads properly
      print();
      this.afterPrint = true;
    }, 400);
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }

  afterPrint = false;
  afterFocus = false;
  isDesktopChrome = false;
  // If the browser is Desktop Chrome the following even listener works perfectly
  @HostListener('window:afterprint')
  onAfterPrint() {
    this.redirectTo('/list-seva-users');
  }

// For other browser detect print finish
  @HostListener('window:focus')
  onFocusafterprint() {
    if(!this.isDesktopChrome && this.afterPrint){
      setTimeout(()=>{
      history.back();
    }, 200);
  }
  }


 

}
