import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  constructor(private httpc:HttpClient,
    private globalService:GlobalService,) { }

  getCityLists(){
    return this.httpc.get(this.globalService.CityApi);
  }

  addNewCity(formdata){
    return this.httpc.post(this.globalService.CityApi,formdata);
  }

  getStateBasedCityList(sid){
    return this.httpc.get(this.globalService.CityApi+"/"+sid);
  }
}
