import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  myform:FormGroup;
  isLoggedIn:boolean = false;

  constructor(private fb:FormBuilder,
    private router:Router,
    private userservice:UsersService) { }

  ngOnInit(): void {
    this.isLoggedIn = this.userservice.getAuthStatus();
    if(this.isLoggedIn) 
       this.router.navigateByUrl('dashboard');
    this.myform = this.fb.group({
      'inputEmailId':this.fb.control('',[Validators.email]),
      'inputPassword':this.fb.control('',[Validators.required,Validators.minLength(5)]),
      
    })
  }

  onFormSubmit(){
    this.userservice.loginUser(this.myform.value);
  }

}
