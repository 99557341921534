import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './webview/Auth/auth-guard';
import { AddBranchComponent } from './webview/pages/branches/add-branch/add-branch.component';
import { ListBranchesComponent } from './webview/pages/branches/list-branches/list-branches.component';
import { ListSubBranchesComponent } from './webview/pages/branches/list-sub-branches/list-sub-branches.component';
import { SubBranchComponent } from './webview/pages/branches/sub-branch/sub-branch.component';
import { ViewBranchComponent } from './webview/pages/branches/view-branch/view-branch.component';
import { ViewSubBranchComponent } from './webview/pages/branches/view-sub-branch/view-sub-branch.component';
import { DashboardComponent } from './webview/pages/dashboard/dashboard.component';
import { ExportPdfComponent } from './webview/pages/export-pdf/export-pdf.component';
import { AddCityComponent } from './webview/pages/extras/add-city/add-city.component';
import { HomePageComponent } from './webview/pages/home-page/home-page.component';
import { LoginComponent } from './webview/pages/login/login.component';
import { AddStudentComponent } from './webview/pages/students/add-student/add-student.component';
import { ListStudentsComponent } from './webview/pages/students/list-students/list-students.component';
import { ViewStudentComponent } from './webview/pages/students/view-student/view-student.component';
import { AddUserComponent } from './webview/pages/users/add-user/add-user.component';
import { ListSevaUsersComponent } from './webview/pages/users/list-seva-users/list-seva-users.component';
import { ListUsersComponent } from './webview/pages/users/list-users/list-users.component';
import { UserCountComponent } from './webview/pages/users/user-count/user-count.component';
import { ViewUserComponent } from './webview/pages/users/view-user/view-user.component';




const routes: Routes = [
  {path:'',component:LoginComponent},
  {path:'add-branch',component:AddBranchComponent,canActivate : [AuthGuard]},
  {path:'list-branches',component:ListBranchesComponent,canActivate : [AuthGuard]},
  {path:'view-branch/:bid',component:ViewBranchComponent,canActivate : [AuthGuard]},

  {path:'add-city',component:AddCityComponent,canActivate : [AuthGuard]},

  {path:'add-sub-branch',component:SubBranchComponent,canActivate : [AuthGuard]},
  {path:'list-sub-branches',component:ListSubBranchesComponent,canActivate : [AuthGuard]},
  {path:'view-sub-branch/:sbid',component:ViewSubBranchComponent,canActivate : [AuthGuard]},

  {path:'add-user',component:AddUserComponent,canActivate : [AuthGuard]},
  {path:'list-users',component:ListUsersComponent,canActivate : [AuthGuard]},
  {path:'list-seva-users',component:ListSevaUsersComponent,canActivate : [AuthGuard]},
  {path:'view-user/:uid',component:ViewUserComponent,canActivate : [AuthGuard]},
  {path:'user-count',component:UserCountComponent,canActivate : [AuthGuard]},

  {path:'add-student',component:AddStudentComponent,canActivate : [AuthGuard]},
  {path:'list-students',component:ListStudentsComponent,canActivate : [AuthGuard]},
  {path:'view-student/:sid',component:ViewStudentComponent,canActivate : [AuthGuard]},
  {path:'dashboard',component:DashboardComponent,canActivate : [AuthGuard]},
  {path:'login',component:LoginComponent}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
