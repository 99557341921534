<div class="hold-transition login-page login-box">
    <div class="login-logo">
      <a href="/"><b>HARI</b>MANDIR</a>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Sign in to start your session</p>
  
        <form  method="POST"
        [formGroup]="myform"
        (submit)="onFormSubmit()">
          <div class="input-group mb-3">
            <input type="email" class="form-control" 
                    placeholder="Email"
                    formControlName="inputEmailId">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
            <div style="width:100%">
              <app-show-errors [control]="myform.controls.inputEmailId"></app-show-errors>
            </div>
          </div>
         
          <div class="input-group mb-3">
            <input type="password" class="form-control"
                   placeholder="Password"
                   formControlName="inputPassword" 
                   >
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
            <div style="width:100%">
              <app-show-errors [control]="myform.controls.inputPassword"></app-show-errors>
            </div>
            
          </div>
          <div class="row">
            <!-- /.col -->
            <div class="col-5">
              <button type="submit" 
              [disabled]="myform.invalid" 
              class="btn btn-primary btn-block">Sign In</button>
            </div>
            <!-- /.col -->
          </div>
        </form>
        <p class="mb-1">
          <a href="#">I forgot my password</a>
        </p>
      </div>
      <!-- /.login-card-body -->
    </div>
</div>