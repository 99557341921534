import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class StatesService {

  constructor(private httpc:HttpClient,
              private globalService:GlobalService,) { }


  getStatesList(){
    return this.httpc.get(this.globalService.StateApi);
  }
}
