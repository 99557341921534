import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private token: string;
  private AuthStatus: boolean = false;
  private userrole: number = -1;
  private tokenTimer: any;

  public AuthStatusEmitter = new Subject<any>();


  constructor(private httpc: HttpClient,
    private router:Router,
    private globalService: GlobalService,) { }

  getAuthStatus() {
    return this.AuthStatus;
  }

  getAuthStatusListener() {
    return this.AuthStatusEmitter.asObservable();
  }

  getUserRole() {
    return this.userrole;
  }

  
  getToken() {
    return this.token;
  }

  //Auth Related Functions 
  logout() {
    this.token = null;
    this.AuthStatus = false;
    this.AuthStatusEmitter.next({ 'loginStatus': false, role: '-1' });
    clearTimeout(this.tokenTimer);
    this.clearLocallyAuthData();
    this.router.navigate(['/']);
  }

  // Local Storage Saving & Deleting
  private saveLocallyAuthData(token: string, expiresInDate: Date, userrole: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiresInDate', expiresInDate.toISOString());
    localStorage.setItem('userrole', userrole);
  }

  private clearLocallyAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresInDate');
    localStorage.removeItem('userrole');
  }


  // Set AuthToken Expiring Timer

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }



  loginUser(formdata: any) {
    this.httpc.post<{
      token: string,
      expiresIn: number,
      userrole: number,
      userid:number
    }>
      (this.globalService.UserApi + '/login', formdata)
      .subscribe((resultdata) => {
        console.log('User Logged In');
        console.log(resultdata);
        this.token = resultdata.token;
        this.userrole = resultdata.userrole;
        const tokenexpiresIn = resultdata.expiresIn;
        this.setAuthTimer(tokenexpiresIn); // its 1 hour period if tokenexpiresIn=3600
        const currentdate = new Date();
        const exp_date = new Date(currentdate.getTime() + resultdata.expiresIn * 1000);
        this.saveLocallyAuthData(this.token, exp_date, this.userrole.toString());
        this.AuthStatus = true;
        this.AuthStatusEmitter.next({ loginStatus: true, role: this.userrole });
        this.router.navigate(['dashboard']);
      },
        error => {
          console.log(error.error.message);
        //  this._flashMessageService.show('Error In Registration', { cssClass: 'alert-success', timeout: 5000 });
         // this._flashMessageService.grayOut(true);
         // this.modelservice.displayDialog('Alert Message', error.error.message);
        })
  }

  // Automatically Authenticate User If token is not Expired

  AutoAuthUser() {
    const localauthInfo = this.getAuthData();
    console.log("TRYING TO AUTO ATUTH");
    if (localauthInfo == null) {
      return;
    }
    const now_date = new Date();

    const rem_expiresInTime = localauthInfo.expiresInDate.getTime() - now_date.getTime();
    //console.log(rem_expiresInTime);

    if (rem_expiresInTime > 0) {
      console.log("CAME HERE");
      this.token = localauthInfo.token;
      this.AuthStatus = true;
      this.userrole = Number(localauthInfo.userrole);
      this.setAuthTimer(rem_expiresInTime / 1000);
      this.AuthStatusEmitter.next({ loginStatus: true, role: this.userrole });
      this.router.navigate(['dashboard']);
    }
  }

  // Getting Auth Data From Local Storage

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expdate = localStorage.getItem('expiresInDate');
    const userrole = localStorage.getItem('userrole');
    if (!token || !expdate || !userrole) {
      return;
    }

    return {
      token: token,
      expiresInDate: new Date(expdate),
      userrole: userrole
    };
  }

  addNewUser(formdata) {
    return this.httpc.post(this.globalService.UserApi, formdata);
  }

  getUserLists() {
    return this.httpc.get(this.globalService.UserApi);
  }

  getSevaUserLists(){
    return this.httpc.get(this.globalService.UserApi+"/seva");
  }

  getUserDetails(uid) {
    return this.httpc.get(this.globalService.UserApi + "/" + uid);
  }

  updateUserDetails(uid, formdata) {
    return this.httpc.put(this.globalService.UserApi + "/" + uid, formdata);
  }

  deleteUser(uid) {
    return this.httpc.delete(this.globalService.UserApi + "/" + uid);

  }

  updateUserCount(formdata){
    return this.httpc.post(this.globalService.UserApi + "/usercounter/",formdata);
  }


}
