import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
  selector : 'app-show-errors',
  template : `
             <div class="error_msg" *ngIf="shouldShowErrors()">
             <span *ngFor="let error of listOfErrors()">{{error}}</span>
             
             </div>`
})
export class DisplayErrorsComponent {

  private static readonly errorMessages = {
    'required': () => 'This field is required',
    'email' : () => 'Invalid Email Id Provided',
    'minlength': (params) => 'Min No of characters is ' + params.requiredLength,
    'maxlength': (params) => 'The max allowed number of characters is ' + params.requiredLength,
    'pattern': (params) => 'The required pattern is: ' + params.requiredPattern,
    'years': (params) => params.message,
    'countryCity': (params) => params.message,
    'uniqueName': (params) => params.message,
    'telephoneNumbers': (params) => params.message,
    'telephoneNumber': (params) => params.message,
    'pdfWord' :  (params) => params.message,
    'number' : (params) => params.message,
    'reqfile' : (params) => params.message,
    'isMatching':(params) => 'Password Mismatch',
    'invalidImage':() => 'Invalid Image',
    'serverError':(params)=>params.msg
  };

  @Input()
  control: AbstractControlDirective | AbstractControl;

  shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched);
  }


  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]));
  }

  private getMessage(type: string, params: any) {
    return DisplayErrorsComponent.errorMessages[type](params);
  }



}
