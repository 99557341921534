<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>User Counter</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Update User Count</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
  
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- left column -->
          <div class="col-md-12">
            <!-- jquery validation -->
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Update Today's User Count</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form id="userForm" method="POST" [formGroup]="myform" (submit)="onFormSubmit()">
                <div class="card-body">
                   <div class="form-group">
                    <label for="inputDate">Select Date</label>
                    <div class="row">
                      <div class="col-6">
                        <input type="text" name="inputDate"
                          [ngClass]="myform.controls.inputDate.touched&&myform.controls.inputDate.errors?'is-invalid form-control':'form-control'"
                          formControlName="inputDate" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" 
                          placeholder="yyyy-mm-dd">
                      </div>
                      <div class="col-2">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">Pick
                            Date</button>
                        </div>
                      </div>
                    </div>
  
  
                    <app-show-errors [control]="myform.controls.inputDate"></app-show-errors>
                  </div>
                 <div class="form-group">
                    <label for="inputNumber">Today's Count</label>
                    <input type="number" name="inputNumber"
                      [ngClass]="myform.controls.inputNumber.touched&&myform.controls.inputNumber.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputNumber" placeholder="Enter Number">
                    <app-show-errors [control]="myform.controls.inputNumber"></app-show-errors>
                 </div>
                 
                  
  
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                  <button type="submit" [disabled]="myform.invalid" class="btn btn-primary">Add Student</button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
          <!-- right column -->
          <div class="col-md-6">
  
          </div>
          <!--/.col (right) -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  
  </div>