
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject} from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from './shared/services/users.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'HariMandirApp';
  constructor(@Inject(DOCUMENT) private document: Document,
              private uservice: UsersService) {
               
               }
  all_links_loaded:boolean = false;
  css_count = 0 ;
  ngOnInit(): void {
   


   // this.loadStyle('call.min.css');
    this.loadStyle('ctempusdominus-bootstrap-4.min.css');
    this.loadStyle('cicheck-bootstrap.min');
    this.loadStyle('cadminlte.min.css');
    this.loadStyle('cOverlayScrollbars.min.css');
    this.loadStyle('cdaterangepicker.css');
    this.loadStyle('csummernote-bs4.min.css');
    this.loadStyle('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');

    

   
    setTimeout(() => {
      this.all_links_loaded = true
      this.uservice.AutoAuthUser();
      this.loadScript('jquery.min.js');
      this.loadScript('jquery-ui.min.js');
      this.loadScript('appscript.js');
      this.loadScript('bootstrap.bundle.min.js');
      this.loadScript('moment.min.js');
      this.loadScript('daterangepicker.js');
      this.loadScript('tempusdominus-bootstrap-4.min.js');
      this.loadScript('summernote-bs4.min.js');
      this.loadScript('jquery.overlayScrollbars.min.js');
      this.loadScript('adminlte.js');
      //this.loadScript('demo.js');
      this.loadScript('dashboard.js');
    }, 30);
    

   
  }

  ngAfterViewInit() {
    console.log('cme here');
   
    
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `${styleName}`;
      head.appendChild(style);
    
  }

  loadScript(styleName: string) {
    const bhead = this.document.getElementsByTagName('body')[0];
    const script = this.document.createElement('script');
       script.type = 'text/javascript';
       script.src = `${styleName}`;
       script.async = false;
       script.charset = 'utf-8';
       bhead.appendChild(script);
    
  }
}


