<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <flash-messages></flash-messages>
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Add New Branch</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Add New Branch</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- jquery validation -->
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Add New Branch</h3>
            </div>
            <!-- /.card-header -->


            <div class="card-body">
              <!-- form start -->
              <form id="userForm" method="POST" [formGroup]="myform" (submit)="onFormSubmit()">
                <div class="form-group">
                  <label for="inputBranchName">Enter Branch Unique Name</label>
                  <input type="text" name="inputBranchName"
                    [ngClass]="myform.controls.inputBranchName.touched&&myform.controls.inputBranchName.errors?'is-invalid form-control':'form-control'"
                    formControlName="inputBranchName" placeholder="Enter Branch Name">
                  <app-show-errors [control]="myform.controls.inputBranchName"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputBranchName">Enter Branch Code</label>
                  <input type="text" name="inputBranchCode"
                    [ngClass]="myform.controls.inputBranchCode.touched&&myform.controls.inputBranchCode.errors?'is-invalid form-control':'form-control'"
                    formControlName="inputBranchCode" placeholder="Enter Branch Code">
                  <app-show-errors [control]="myform.controls.inputBranchCode"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputContact">Contact No</label>
                  <input type="text" name="inputContact"
                    [ngClass]="myform.controls.inputContact.touched&&myform.controls.inputContact.errors?'is-invalid form-control':'form-control'"
                    formControlName="inputContact" placeholder="Enter Contact No">
                  <app-show-errors [control]="myform.controls.inputContact"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputLandline">Landline No</label>
                  <input type="text" name="inputLandline"
                    [ngClass]="myform.controls.inputLandline.touched&&myform.controls.inputLandline.errors?'is-invalid form-control':'form-control'"
                    formControlName="inputLandline" placeholder="Enter Landline No">
                  <app-show-errors [control]="myform.controls.inputLandline"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputState">Select State</label>
                  <select class="form-control"
                    (ngModelChange)="updateCityList($event)"
                    [ngClass]="myform.controls.inputState.touched&&myform.controls.inputState.errors?'is-invalid form-control':'form-control'"
                    formControlName="inputState">
                    <option *ngFor="let state of state_list" value="{{state.id}}">{{state.sname}}</option>
                  </select>
                  <app-show-errors [control]="myform.controls.inputState"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputCity">Select City</label>
                  <select class="form-control"
                    [ngClass]="myform.controls.inputCity.touched&&myform.controls.inputCity.errors?'is-invalid form-control':'form-control'"
                    formControlName="inputCity">
                    <option *ngFor="let city of city_list" value="{{city.id}}">{{city.cname}}</option>
                    
                  </select>
                  <app-show-errors [control]="myform.controls.inputCity"></app-show-errors>
                </div>
               
                <div class="form-group">
                  <label for="inputAddress">Address</label>
                  <textarea class="form-control" rows="3" name="inputAddress"
                    [ngClass]="myform.controls.inputAddress.touched&&myform.controls.inputAddress.errors?'is-invalid form-control':'form-control'"
                    formControlName="inputAddress" placeholder="Enter Address"></textarea>

                  <app-show-errors [control]="myform.controls.inputAddress"></app-show-errors>
                </div>
                <!-- /.card-body -->
                <div class="card-footer p-0">
                  <button type="submit" [disabled]="myform.invalid" class="btn btn-primary">Add Branch</button>
                </div>
              </form>
            </div>

          </div>
          <!-- /.card -->
        </div>
        <!--/.col (left) -->
        <!-- right column -->
        <div class="col-md-6">

        </div>
        <!--/.col (right) -->
      </div>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </section>
  <!-- /.content -->

</div>