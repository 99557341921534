<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <flash-messages></flash-messages>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Add Sub City</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Add New City</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
  
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- left column -->
          <div class="col-md-12">
            <!-- jquery validation -->
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Add New City</h3>
              </div>
              <!-- /.card-header -->
  
  
              <div class="card-body">
                <!-- form start -->
                <form id="userForm" method="POST" [formGroup]="myform" (submit)="onFormSubmit()">
                  <div class="form-group">
                    <label for="inputCity">Enter City Name</label>
                    <input type="text" name="inputCity"
                      [ngClass]="myform.controls.inputCity.touched&&myform.controls.inputCity.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputCity" placeholder="Enter City Name">
                    <app-show-errors [control]="myform.controls.inputCity"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputState">Select State</label>
                    <select class="form-control"
                      [ngClass]="myform.controls.inputState.touched&&myform.controls.inputState.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputState">
                      <option *ngFor="let eachState of state_list" value="{{eachState.id}}">{{eachState.sname}}</option>
                      
                    </select>
                    <app-show-errors [control]="myform.controls.inputState"></app-show-errors>
                  </div>
          
                 
                  <!-- /.card-body -->
                  <div class="card-footer p-0">
                    <button type="submit" [disabled]="myform.invalid" class="btn btn-primary">Add City</button>
                  </div>
                </form>
              </div>
  
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
          <!-- right column -->
          <div class="col-md-6">
  
          </div>
          <!--/.col (right) -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  
  </div>