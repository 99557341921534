<!-- Main Sidebar Container -->
<aside *ngIf="isLoggedIn||userRole>=0" class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="/" class="brand-link">
      <img src="assets/img/omlogo.png" alt="HariMandir Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-light">HariMandirApp</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="assets/img/avatar5.png" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a href="#" class="d-block">Yogesh Naik</a>
        </div>
      </div>

       <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-header">Admin Section</li>
          <!--Branch Section-->
          <li class="nav-item">
            <a href="#"  class="nav-link cbright">
              <i class="nav-icon fas fa-home"></i>
              <p>
               Branch Section
                <i class="fas fa-angle-left right"></i>
              </p>
            </a> 
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="add-branch" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Add New Branch</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="list-branches" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>List All Branches</p>
                </a>
              </li>
            </ul>
          </li>
          <!--Sub Branch Section-->
          <li class="nav-item">
            <a href="#" class="nav-link cbright">
              <i class="nav-icon fas fa-code-branch"></i>
              <p>
               Sub Branch Section
                <i class="fas fa-angle-left right"></i>
              </p>
            </a> 
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="add-sub-branch" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Add New SubBranch</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="list-sub-branches" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>List All SubBranches</p>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-user"></i>
              <p>
                User Section
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="add-user" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Add New User</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="user-count" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Update User Count</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="list-users" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>List All Users</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="list-seva-users" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>List Seva Users</p>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-book"></i>
              <p>
                Students Section
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="add-student" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Add New Student</p>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="list-students" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>List All Students</p>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link cbright">
              <i class="nav-icon fas fa-angle-double-right"></i>
              
              <p>
                Miscellaneous Section
                <i class="fas fa-angle-left right"></i>
              </p>
            </a> 
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a routerLink="add-city" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Add New City</p>
                </a>
              </li>
              
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" (click)="logOut($event)" class="nav-link">
              <i class="nav-icon fas fa-sign-out-alt"></i>
              <p>LogOut</p>
            </a>
          </li>
        
          
        
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>