import { Component, OnInit, ViewChild,HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BranchService } from 'src/app/shared/services/branch.service';
import { ModelService } from 'src/app/webview/components/model/model.service';
import { NumericEditor } from 'src/app/webview/components/validators-error-handlers/numeric-editor/numeric-editor.component';

@Component({
  selector: 'app-list-branches',
  templateUrl: './list-branches.component.html',
  styleUrls: ['./list-branches.component.css']
})
export class ListBranchesComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  @ViewChild('fitscreen') fitscreen: any;

  public frameworkComponents;
  private gridApi;
  private gridColumnApi;


  private pgridApi;
  private pgridColumnApi;
  
  private temp_row_data_holder;

  is_print_view:boolean = false;

  public columnList: any = ['BranchName','BCode','City','State','Contact No','LandlineNo','Address'];
  public printColumnList: any = ['BranchName','BCode','City','State','Contact No','LandlineNo'];
  plan_table_coloumns: any = [];
  print_plan_table_coloumns: any = [];
  plantableData: any = []; 

  city_list_data = [];
  state_list_data = [];
  
  isDataFetched: boolean = false;
  constructor(private bservice:BranchService,
              private router:Router,
              private _flashMessageService:FlashMessagesService,
              private modelservice:ModelService) { }



  lookupKey(mappings, name) {
    for (var key in mappings) {
        if (mappings.hasOwnProperty(key)) {
            if (name === mappings[key]) {
                return key;
            }
        }
    }
}

setColoumnStrcutureForView(){
  this.plan_table_coloumns = [];
  this.columnList.forEach(element => {
    let each_col = {
      headerName: element,
      //type:(key=="% Completed")?'number':'normal',
      field: element,
      resizable: true,
      lockPosition: false,//disable drag option of coloumns
      sortable: true,
      //filter: true,
      editable: true,
      hide: (element == "id") ? true : false
    }
    if(element=="BCode")  {
      each_col['cellEditor'] = 'numericEditor'
      each_col['width'] = 100;
    }
    if(element=="LandlineNo")  {
      each_col['cellEditor'] = 'numericEditor'
      each_col['width'] = 140;
    }
    if(element=="Contact No")  {
      each_col['cellEditor'] = 'numericEditor'
      each_col['width'] = 150;
    }
    if(element=="State")  {
      each_col['cellEditor'] = 'numericEditor'
      each_col['width'] = 150;
    }
    if(element=="City")  {
      each_col['cellEditor'] = 'numericEditor'
      each_col['width'] = 100;
    }
    if(element=="State")  {
      each_col['cellEditor'] = 'select',
      each_col['cellEditorParams'] = {
        values: Object.keys(this.state_list_data)
      },
      each_col['valueFormatter'] = (params) =>{
        //return this.lookupValue(this.state_list_data, params.value);
        return this.state_list_data[params.value];
      },
     each_col['valueParser'] = (params) =>{
        return this.lookupKey(this.state_list_data, params.newValue);
     }

    }
    if(element=="Address"){
      each_col['cellStyle'] = { 'white-space': 'normal' }
      each_col['wrapText'] = true
      each_col['autoHeight'] = true
    }
    if(element=="City")  {
      each_col['cellEditor'] = 'select',
      each_col['cellEditorParams'] = {
        values:  Object.keys(this.city_list_data)
      },
      each_col['valueFormatter'] = (params) =>{
         return this.city_list_data[params.value];
      },
     each_col['valueParser'] = (params) =>{
        return this.lookupKey(this.city_list_data, params.newValue);
     }

    }
    each_col['filter'] = true;
    this.plan_table_coloumns.push(each_col);
  });

  this.plan_table_coloumns.push({
    headerName: "Actions",
    minWidth: 150,
    cellRenderer: this.actionCellRenderer,
    editable: false,
    colId: "actions"
  });
}

getBranchList(){
  this.isDataFetched = false;
  this.plantableData = [];
  this.bservice.getBranchLists()
  .subscribe((res:any)=>{
   //console.log(res);
   this.city_list_data = res.clist;
   this.city_list_data = this.city_list_data.reduce(
     (obj, item) => Object.assign(obj, { [item.id]: item.cname }), {}
   );
   
   this.state_list_data = res.slist;
   this.state_list_data = this.state_list_data.reduce(
     (obj, item) => Object.assign(obj, { [item.id]: item.sname }), {}
   );
   //console.log(this.state_list_data);
   //console.log(this.city_list_data);
   this.setColoumnStrcutureForView();
   res.blist.forEach(eachrow => {
     let each_data ={
       'id':eachrow.id,
       'BranchName':eachrow.bname,
       'BCode':eachrow.bcode,
       'City':this.city_list_data[eachrow.city_id],
       'State':this.state_list_data[eachrow.state_id],
       'Contact No':eachrow.bcontact,
       'LandlineNo':eachrow.blandline,
       'Address':eachrow.baddress
     }
     this.plantableData.push(each_data);
   });
   this.isDataFetched =true;
   console.log(this.plantableData);
  })
}
 

ngOnInit(): void {
    
    this.frameworkComponents = {
      numericEditor: NumericEditor,   
    };
    
    this.getBranchList();

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)){
      this.isDesktopChrome = false;
    }
    else if(/Chrome/i.test(navigator.userAgent)){
      this.isDesktopChrome = true;
    }
    
  }

  isFitToScreenSet = false;
  
  fittoscreen(event){
    event.preventDefault();
    if (this.isFitToScreenSet) {
      var allColumnIds = [];
      this.agGrid.columnApi.getAllColumns().forEach((column: any) => {
        allColumnIds.push(column.colId);
      });
      
      this.agGrid.columnApi.autoSizeColumns(allColumnIds, false);
      this.isFitToScreenSet = false;
    } else {
      this.agGrid.api.sizeColumnsToFit();
      this.isFitToScreenSet = true;
      
    }
  }
  actionCellRenderer(params) {
    let eGui = document.createElement('div');
    eGui.innerHTML = `
          <button  
            class="btn btn-success"
            data-action="view">
                 View  
          </button>
          <button  
          class="btn btn-danger"
          data-action="delete">
               Delete  
          </button>
          `;
    return eGui;
  }
  onGridReady(params) {
    console.log(params);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    
  }

   //AGGrid Table cell on clicked
   onCellClicked(event) {
    console.log("Cell Clicked");
    console.log(event.data.id);
    //this.temp_row_data_holder = event.data;
    let action = event.event.target.dataset.action;
    if (action === "delete" || action === "view") {
      if (action === "delete") {
        console.log("its delete operation for id : " + event.data.id);
        if(confirm("Are you sure to Delete ")) {
          this.bservice.deleteBranch(event.data.id)
                       .subscribe(res=>{
                        this._flashMessageService.show('Branch Deleted Successfully', { cssClass: 'alert-danger', timeout: 5000 });
                        this.modelservice.displayDialog('Alert Message', "Branch Has Been Deleted");
                        this.getBranchList();
                      })
          
        }
      }

      if (action === "view") {
       this.router.navigate(['view-branch',event.data.id]);
      }

    }
  }

  onRowEditingStopped(event){
    console.log("editing stopped");
    //console.log(event.data);
    // console.log(event.data.Owner);
    let edited_id = event.data.id;
    const formdata = {
      'inputBranchName':event.data.BranchName,
      'inputBranchCode':event.data.BCode,
      'inputContact':event.data['Contact No'],
      'inputLandline':event.data.LandlineNo,
      'inputCity':event.data.City,
      'inputState':event.data.State,
      'inputAddress':event.data.Address,
    }
    this.bservice.updateBranchDetails(edited_id,formdata)
                 .subscribe((resultdata)=>{
                  
                  this.modelservice.displayDialog('Alert Message', "Branch Details Updated!");
                  
                },
                error=>{
                  console.log(error.error.message);
                  this._flashMessageService.grayOut(true);
                  this.modelservice.displayDialog('Alert Message', error.error.message);
                });
  }

  onRowEditingStarted(event){
    console.log("Editing Started");
  }

  exportPdf(is_done:boolean){
    this.is_print_view = true;
    this.printColumnList.forEach(element => {
      let each_col = {
        headerName: element,
        //type:(key=="% Completed")?'number':'normal',
        field: element,
        resizable: true,
        lockPosition: false,//disable drag option of coloumns
        sortable: true,
        //filter: true,
        width:150,
        editable: true,
        hide: (element == "id") ? true : false
      }
      each_col['filter'] = true;
      this.print_plan_table_coloumns.push(each_col);
    });
     
    this.gridApi.setColumnDefs(this.print_plan_table_coloumns);
   // console.log(this.gridApi.getSortModel());
   // console.log(this.gridApi.getFilterModel());
  
    this.gridColumnApi.setColumnState(this.gridColumnApi.getColumnState());
    this.gridApi.setSortModel(this.gridApi.getSortModel());
    this.gridApi.setFilterModel(this.gridApi.getFilterModel());
    this.gridApi.sizeColumnsToFit();
    // call setDomLayout('print') for proper print layout (Api function provided by ag grid)
    this.gridApi.setDomLayout('print');
    // window.blur();
    
    setTimeout(()=>{
      // call print after a time out so that print layout loads properly
      print();
      this.afterPrint = true;
    }, 400);
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }

  afterPrint = false;
  afterFocus = false;
  isDesktopChrome = false;
  // If the browser is Desktop Chrome the following even listener works perfectly
  @HostListener('window:afterprint')
  onAfterPrint() {
    this.redirectTo('/list-branches');
  }

// For other browser detect print finish
  @HostListener('window:focus')
  onFocusafterprint() {
    if(!this.isDesktopChrome && this.afterPrint){
      setTimeout(()=>{
      history.back();
    }, 200);
  }
  }

}
