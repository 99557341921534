import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BranchService } from 'src/app/shared/services/branch.service';
import { CitiesService } from 'src/app/shared/services/cities.service';
import { StatesService } from 'src/app/shared/services/states.service';
import { ModelService } from 'src/app/webview/components/model/model.service';

@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.css']
})
export class AddBranchComponent implements OnInit {

  myform:FormGroup;
  city_list = [];
  state_list = [];


  constructor(private fb:FormBuilder,
              private sservice:StatesService,
              private router:Router,
              private _flashMessageService:FlashMessagesService,
              private modelservice:ModelService,
              private cservice:CitiesService,
              private cityService:CitiesService,
              private bservice:BranchService) { }

  ngOnInit(): void {
   
    this.sservice.getStatesList()
                 .subscribe((res:any)=>{
      this.state_list = res.slist;
    });

    

    this.myform = this.fb.group({
      'inputBranchName':this.fb.control('',[Validators.required]),
      'inputBranchCode':this.fb.control('',[Validators.required]),
      'inputContact':this.fb.control('',[Validators.required]),
      'inputLandline':this.fb.control('',),
      'inputCity':this.fb.control('',[Validators.required]),
      'inputState':this.fb.control('',[Validators.required]),
      'inputAddress':this.fb.control('',[Validators.required]),
    })
  }

  onFormSubmit(){
    console.log(this.myform.value);
    this.bservice.addNewBranch(this.myform.value)
                 .subscribe((resultdata)=>{
                  
                  this.modelservice.displayDialog('Alert Message', "New Branch Has Been Added");
                  this.router.navigate(['list-branches']);
                },
                error=>{
                  console.log(error.error.message);
                  this._flashMessageService.grayOut(true);
                  this.modelservice.displayDialog('Alert Message', error.error.message);
                });
  }

  updateCityList(event){
    this.cityService.getStateBasedCityList(event)
                    .subscribe((res:any)=>{
                      this.city_list = res.clist;
                    })
  }

}
