import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BranchService } from 'src/app/shared/services/branch.service';
import { ModelService } from 'src/app/webview/components/model/model.service';

@Component({
  selector: 'app-view-sub-branch',
  templateUrl: './view-sub-branch.component.html',
  styleUrls: ['./view-sub-branch.component.css']
})
export class ViewSubBranchComponent implements OnInit {

  myform: FormGroup;
  main_branch_list = [];

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _flashMessageService: FlashMessagesService,
    private modelservice: ModelService,
    private bservice: BranchService) { }

  private sbid;
  ngOnInit(): void {
    this.bservice.getBranchLists()
      .subscribe((res: any) => {
        this.main_branch_list = res.blist;
      });
    this.myform = this.fb.group({
      'inputSBranchName': this.fb.control('', [Validators.required]),
      'inputSBranchCode': this.fb.control('', [Validators.required]),
      'inputBranch': this.fb.control('', [Validators.required]),

    })

    this.route.paramMap.subscribe((pmap: ParamMap) => {
      if (pmap.has('sbid')) {
        this.sbid = pmap.get('sbid');

        this.bservice.getSubBranchDetails(this.sbid)
          .subscribe((res: any) => {
            console.log(res);
            this.myform.patchValue({
              'inputSBranchName': res.sbdata[0].sbname,
              'inputSBranchCode': res.sbdata[0].sbcode,
              'inputBranch': res.sbdata[0].branch_id,

            })

            //this.myform.setValidators(this.validateForm);
          });
      }
    });
  }

  onFormSubmit() {
    console.log(this.myform.value);
    this.bservice.updateSubBranchDetails(this.sbid,this.myform.value)
      .subscribe((resultdata) => {

        this.modelservice.displayDialog('Alert Message', "SubBranch Has Been Updated");
        this.router.navigate(['list-sub-branches']);
      },
        error => {
          console.log(error.error.message);
          this._flashMessageService.grayOut(true);
          this.modelservice.displayDialog('Alert Message', error.error.message);
        });
  }

}
