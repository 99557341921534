import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BranchService } from 'src/app/shared/services/branch.service';
import { CitiesService } from 'src/app/shared/services/cities.service';
import { StatesService } from 'src/app/shared/services/states.service';
import { ModelService } from 'src/app/webview/components/model/model.service';

@Component({
  selector: 'app-view-branch',
  templateUrl: './view-branch.component.html',
  styleUrls: ['./view-branch.component.css']
})
export class ViewBranchComponent implements OnInit {

  myform:FormGroup;
  city_list = [];
  state_list = [];

  constructor(private route:ActivatedRoute,
    private fb:FormBuilder,
    private sservice:StatesService,
    private router:Router,
    private _flashMessageService:FlashMessagesService,
    private modelservice:ModelService,
    private cservice:CitiesService,
    private bservice:BranchService) { }

  private bid;
  ngOnInit(): void {
    
    this.myform = this.fb.group({
      'inputBranchName':this.fb.control('',[Validators.required]),
      'inputBranchCode':this.fb.control('',[Validators.required]),
      'inputContact':this.fb.control('',[Validators.required]),
      'inputLandline':this.fb.control('',[Validators.required]),
      'inputCity':this.fb.control('',[Validators.required]),
      'inputState':this.fb.control('',[Validators.required]),
      'inputAddress':this.fb.control('',[Validators.required]),
    })


    this.route.paramMap.subscribe((pmap:ParamMap)=>{
      if(pmap.has('bid')){
        this.bid = pmap.get('bid');

        this.sservice.getStatesList()
                    .subscribe((res:any)=>{
          this.state_list = res.slist;
        });

        this.cservice.getCityLists()
                    .subscribe((res:any)=>{
           this.city_list = res.clist;
        });

        this.bservice.getBranchDetails(this.bid)
                     .subscribe((res:any)=>{
                       console.log(res);
                      this.myform.patchValue({
                          'inputBranchName':res.bdata[0].bname,
                          'inputBranchCode':res.bdata[0].bcode,
                          'inputContact':res.bdata[0].bcontact,
                          'inputLandline':res.bdata[0].blandline,
                          'inputCity':res.bdata[0].city_id,
                          'inputState':res.bdata[0].state_id,
                          'inputAddress':res.bdata[0].baddress,
                          
                        })  
                        
                        //this.myform.setValidators(this.validateForm);
                    });
          }
      });
  }

  validateForm(control: AbstractControl) {
    control['controls'].forEach(
      // make sure to pass {onlySelf: true, emitEvent: false} 
      // or it will recurse indefinitely
      control => control.updateValueAndValidity({onlySelf: true, emitEvent: false})
    );

    return null;
  }
 

  onFormSubmit(){
    console.log(this.myform.value);
    this.bservice.updateBranchDetails(this.bid,this.myform.value)
                 .subscribe((resultdata)=>{
                  
                  this.modelservice.displayDialog('Alert Message', "Branch Details Updated!");
                  this.router.navigate(['list-branches']);
                },
                error=>{
                  console.log(error.error.message);
                  this._flashMessageService.grayOut(true);
                  this.modelservice.displayDialog('Alert Message', error.error.message);
                });
  }

}
