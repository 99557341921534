import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class ModelService {

  constructor() { }

  popup_mesg_emmiter = new Subject<{title: string, message: string}>();

  popup_mesg_listener() {
    return this.popup_mesg_emmiter.asObservable();
  }

  displayDialog(inputTitle: string, inputMessage: string) {
    const popup_data = {
      title : inputTitle,
      message : inputMessage
    };
    this.popup_mesg_emmiter.next(popup_data);
    $('#myModal').modal('show');
  }
}
