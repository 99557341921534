import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
 
  //branches section
  public BranchApi: string = '';
  public StateApi: string = '';
  public CityApi: string = '';
  public SubBranchApi: string = '';
  public UserApi: string = '';
  public StudentApi: string = '';
  public misApi:string ='';
  

  constructor() { 

    //Branch Section
    this.BranchApi = environment.apiUrl+"/branches";

    //Sub Branch Section
    this.SubBranchApi = environment.apiUrl+"/branches/subbranches";

    //State Section
    this.StateApi = environment.apiUrl+"/states";

    //City Section
    this.CityApi = environment.apiUrl+"/cities";

    //User Section
    this.UserApi = environment.apiUrl+"/users";

    //Student Section
    this.StudentApi = environment.apiUrl+"/students";

    //miscellaneous
    this.misApi = environment.apiUrl+"/mis";
  }
}
