import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class StudentsService {

  constructor(private httpc: HttpClient,
    private globalService: GlobalService,) { }

  addNewStudent(formdata) {
    return this.httpc.post(this.globalService.StudentApi, formdata);
  }

  getStudentLists() {
    return this.httpc.get(this.globalService.StudentApi);
  }

  getStudentDetails(sid) {
    return this.httpc.get(this.globalService.StudentApi + "/" + sid);
  }

  updateStudentDetails(uid, formdata) {
    return this.httpc.put(this.globalService.StudentApi + "/" + uid, formdata);
  }

  deleteStudent(uid) {
    return this.httpc.delete(this.globalService.StudentApi + "/" + uid);

  }


}
