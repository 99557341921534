import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit,OnDestroy {

  userSubcription:Subscription;
  userRole:any=-1;
  isLoggedIn:boolean = false;

  constructor(private uservice:UsersService, private router:Router) { }

  ngOnInit(): void {
    this.userRole = this.uservice.getUserRole();
    this.userSubcription = this.uservice.getAuthStatusListener()
                                        .subscribe(AuthData=>{
                                          this.isLoggedIn = AuthData.loginStatus;
                                          this.userRole = AuthData.role;
                                          console.log(AuthData);
                                        })
    this.uservice.getAuthStatus();
  }

  ngOnDestroy(){
    this.userSubcription.unsubscribe();
  }

  logOut(event:any){
    event.preventDefault();
    this.uservice.logout();
    this.router.navigate(['/login']);
  }

}
