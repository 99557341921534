import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-qrcode-image-render',
  template: `
  <qr-code [value]="params" [padding]="10" [size]="150"></qr-code>
  `,
  styleUrls: ['./qrcode-image-render.component.css']
})
export class QrcodeImageRenderComponent implements OnInit, ICellRendererAngularComp, OnDestroy  {

  constructor() { }
  refresh(params: ICellRendererParams) {
    this.params = params.data.qrcode;
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
  }

  public params: any;


  agInit(params: any): void {
   // console.log(params.data.qrcode);
    this.params = params.data.qrcode;
  }

  btnClickedHandler() {
    this.params.clicked(this.params.value);
  }

  ngOnDestroy() {
    // no need to remove the button click handler 
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }

}
