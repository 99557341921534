import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BranchService } from 'src/app/shared/services/branch.service';
import { CitiesService } from 'src/app/shared/services/cities.service';
import { StatesService } from 'src/app/shared/services/states.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { ModelService } from 'src/app/webview/components/model/model.service';
import { AddUserComponent } from '../add-user/add-user.component';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit {

  myform:FormGroup;

  city_list:any = [];
  state_list:any = [];
  branch_list:any = [];
  
  sub_branch_list:any = [];
  selected_branch_id:any;

  private user_id;

  constructor(private fb:FormBuilder,
    private route:ActivatedRoute,
    private router:Router,
    private _flashMessageService:FlashMessagesService,
    private modelservice:ModelService,
    private bservice:BranchService,
    private stateService:StatesService,
    private cityService:CitiesService,
    private userService:UsersService) { }

  ngOnInit(): void {
    this.myform = this.fb.group({
      'inputFirstName':this.fb.control('',[Validators.required]),
      'inputLastName':this.fb.control('',[Validators.required]),
      'inputGender':this.fb.control('',[Validators.required]),
      'inputContact':this.fb.control('',[Validators.required]),
      'inputRole':this.fb.control('',[Validators.required]),
      'inputBranch':this.fb.control('',[Validators.required]),
      'inputSubBranch':this.fb.control(0,[Validators.required]),
      'inputEmail':this.fb.control('',[Validators.required,Validators.email]),
      'inputPassword':this.fb.control('',[Validators.required]),
      'inputCPassword':this.fb.control('',[Validators.required,AddUserComponent.matchValues('inputPassword')]),
      'inputState': this.fb.control('', [Validators.required]),
      'inputCity': this.fb.control('', [Validators.required]),
      'inputAddress': this.fb.control('', [Validators.required]),
    })
    this.bservice.getBranchLists()
                 .subscribe((res:any)=>{
                    this.branch_list = res.blist;
                 })
    this.stateService.getStatesList()
                    .subscribe((res:any)=>{
                      this.state_list = res.slist;
                    })
    this.route.paramMap.subscribe((pmap:ParamMap)=>{
                      if(pmap.has('uid')){
                        this.user_id = pmap.get('uid');
                
                        this.userService.getUserDetails(this.user_id)
                                     .subscribe((res:any)=>{
                                       console.log(res);
                                      this.myform.patchValue({
                                        'inputFirstName':res.udata[0].fname,
                                        'inputLastName':res.udata[0].lname,
                                        'inputGender':res.udata[0].gender,
                                        'inputContact':res.udata[0].contact,
                                        'inputRole':res.udata[0].role,
                                        'inputBranch':res.udata[0].branch_id,
                                        'inputSubBranch':res.udata[0].subbranch_id,
                                        'inputEmail':res.udata[0].emailid,
                                        'inputState': res.udata[0].state_id,
                                        'inputCity': res.udata[0].city_id,
                                        'inputAddress': res.udata[0].address,
                                          
                                        })  
                                        
                                        //this.myform.setValidators(this.validateForm);
                                    });
                          }
                      });
  }

  updateCityList(event){
    this.cityService.getStateBasedCityList(event)
                    .subscribe((res:any)=>{
                      this.city_list = res.clist;
                    })
  }

 

  public static matchValues(matchTo:string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
     }
  }

  updateSubBranchList(event){
    this.selected_branch_id = event;
    this.sub_branch_list = this.bservice.getSubBranchListsForBranch(this.selected_branch_id)
                                        .subscribe((res:any)=>{
                                          console.log(res);
                                          this.sub_branch_list = res.sblist;
                                        })
  }

  updateSubBranchSelectedValue(event){
   // this.selected_subbranch_id = event;
  }

  onFormSubmit(){
    console.log(this.myform.value);
    this.userService.updateUserDetails(this.user_id,this.myform.value)
                 .subscribe((resultdata)=>{
                  
                  this.modelservice.displayDialog('Alert Message', "User Updated Successfully!");
                  this.router.navigate(['list-users']);
                },
                error=>{
                  console.log(error.error.message);
                  this._flashMessageService.grayOut(true);
                  this.modelservice.displayDialog('Alert Message', error.error.message);
                });
  }

}
