<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
   
    <section class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
             <h1>Complete Branch List</h1>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a href="#">Home</a></li>
               <li class="breadcrumb-item active">Complete Branch List</li>
             </ol>
           </div>
         </div>
       </div><!-- /.container-fluid -->
    </section>
   
     <!-- Main content -->
     <section class="content">
       <div class="container-fluid">
         <div class="row">
           <!-- left column -->
           <div class="col-md-12">
             <!-- jquery validation -->
             <div class="card card-primary">
               <div class="card-header">
                 <h3 class="card-title">Complete Branch List</h3>
               </div>
               <!-- /.card-header -->
                 <div class="mtb-2 btn-space" *ngIf="!is_print_view">
                   <button class="btn btn-danger " (click)="fittoscreen($event)"><i class="fas fa-desktop"></i>  Fit to Screen</button>
                   <button class="btn btn-success" value="Export" (click)="exportPdf(false)"><i class="far fa-file-pdf"></i> Export to PDF</button>
                 </div>
                 <div class="card-body" *ngIf="isDataFetched">
                    <ag-grid-angular 
                    #agGrid 
                    id="myGrid"
                    style="margin: auto auto;width: 100%; height: 500px;"
                    class="ag-theme-alpine"
                    [rowData]="plantableData"
                    [scrollbarWidth]="8"
                    [columnDefs]="plan_table_coloumns"
                    [suppressDragLeaveHidesColumns]="true"
                    [frameworkComponents]="frameworkComponents"
                    (cellClicked)="onCellClicked($event)"
                    editType="fullRow"
                    (gridReady)="onGridReady($event)"
                    (rowEditingStopped)="onRowEditingStopped($event)"
                    (rowEditingStarted)="onRowEditingStarted($event)"
                    >
                    </ag-grid-angular>
                  
                 </div>
                 <!-- /.card-body -->
                
                 
             </div>
             <!-- /.card -->
             </div>
           <!--/.col (left) -->
           <!-- right column -->
           <div class="col-md-6">
   
           </div>
           <!--/.col (right) -->
         </div>
         <!-- /.row -->
       </div><!-- /.container-fluid -->
     </section>
     <!-- /.content -->
   
   </div>