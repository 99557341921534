import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { ModelService } from './model.service';

@Component({
  selector: 'app-modal',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.css']
})

export class ModelComponent implements OnInit, OnDestroy {

  datareciever: Subscription;

  title = '';

  message = '';

  constructor(public modelservice: ModelService) { }

  ngOnInit() {

    this.datareciever = this.modelservice.popup_mesg_listener()
                                         .subscribe((popup_data: { title: string, message: string}) => {
                                            this.title = popup_data.title;
                                            this.message = popup_data.message;
                                         });

  }

  ngOnDestroy() {
    this.datareciever.unsubscribe();
  }




}
