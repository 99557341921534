import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationErrorModule } from '../../validation-error-handlers/validation-error/validation-error.module';
import { ModelModule } from 'src/app/webview/components/model/model.module';
import { FlashMessagesModule } from 'angular2-flash-messages';


@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ValidationErrorModule,
    ModelModule,
    
    
  ],
  exports :[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ValidationErrorModule,
    ModelModule,
    FlashMessagesModule
  ]
})
export class SharedFormModule { }
