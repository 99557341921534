<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Student Details</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Student Details</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
  
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- left column -->
          <div class="col-md-12">
            <!-- jquery validation -->
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Student Details</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form id="userForm" method="POST" [formGroup]="myform" (submit)="onFormSubmit()">
                <div class="card-body">
                  <div class="form-group">
                    <label for="inputFirstName">FirstName</label>
                    <input type="text" name="inputFirstName"
                      [ngClass]="myform.controls.inputFirstName.touched&&myform.controls.inputFirstName.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputFirstName" placeholder="Enter FirstName">
                    <app-show-errors [control]="myform.controls.inputFirstName"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputLastName">LastName</label>
                    <input type="text" name="inputLastName"
                      [ngClass]="myform.controls.inputLastName.touched&&myform.controls.inputLastName.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputLastName" placeholder="Enter LastName">
                    <app-show-errors [control]="myform.controls.inputLastName"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputGender">Select Gender</label>
                    <select class="form-control"
                      [ngClass]="myform.controls.inputGender.touched&&myform.controls.inputGender.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputGender">
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                    <app-show-errors [control]="myform.controls.inputGender"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputDOB">Select DOB</label>
                    <div class="row">
                      <div class="col-6">
                        <input type="text" name="inputDOB"
                          [ngClass]="myform.controls.inputDOB.touched&&myform.controls.inputDOB.errors?'is-invalid form-control':'form-control'"
                          formControlName="inputDOB" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker"
                          placeholder="yyyy-mm-dd">
                      </div>
                      <div class="col-2">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">Pick
                            Date</button>
                        </div>
                      </div>
                    </div>
  
  
                    <app-show-errors [control]="myform.controls.inputDOB"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputClass">Select Class</label>
                    <select class="form-control"
                      [ngClass]="myform.controls.inputClass.touched&&myform.controls.inputClass.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputClass">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                    <app-show-errors [control]="myform.controls.inputGender"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputContact">Contact No</label>
                    <input type="text" name="inputContact"
                      [ngClass]="myform.controls.inputContact.touched&&myform.controls.inputContact.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputContact" placeholder="Enter Contact">
                    <app-show-errors [control]="myform.controls.inputContact"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputBranch">Select Branch</label>
                    <select (ngModelChange)="updateSubBranchList($event)" class="form-control"
                      [ngClass]="myform.controls.inputBranch.touched&&myform.controls.inputBranch.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputBranch">
                      <option *ngFor="let eachbranch of branch_list" value={{eachbranch.id}}>{{eachbranch.bname}}</option>
                    </select>
                    <app-show-errors [control]="myform.controls.inputBranch"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputSubBranch">Select SubBranch</label>
                    <select class="form-control" 
                      *ngIf="sub_branch_list.length>0"
                      [ngClass]="myform.controls.inputSubBranch.touched&&myform.controls.inputSubBranch.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputSubBranch">
                      <option value="0">None</option>
                      <option *ngFor="let eachsbranch of sub_branch_list" value={{eachsbranch.id}}>{{eachsbranch.sbname}}
                      </option>
                    </select>
                    <app-show-errors [control]="myform.controls.inputSubBranch"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputBalopasanaBookNo">Balopasana Book No</label>
                    <input type="text" name="inputBalopasanaBookNo"
                      [ngClass]="myform.controls.inputBalopasanaBookNo.touched&&myform.controls.inputBalopasanaBookNo.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputBalopasanaBookNo" placeholder="Enter Balopasana Book No">
                    <app-show-errors [control]="myform.controls.inputBalopasanaBookNo"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputYear">Enrollment Year</label>
                    <select class="form-control"
                      [ngClass]="myform.controls.inputYear.touched&&myform.controls.inputYear.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputYear">
                      <option>2021-2022</option>
                      <option>2022-2023</option>
                      <option>2023-2024</option>
                      <option>2025-2026</option>
                      <option>2027-2028</option>
                      <option>2029-2030</option>
                    </select>
                    <app-show-errors [control]="myform.controls.inputYear"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputState">Select State</label>
                    <select (ngModelChange)="updateCityList($event)" class="form-control"
                      [ngClass]="myform.controls.inputState.touched&&myform.controls.inputState.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputState">
                      <option *ngFor="let eachval of state_list" value={{eachval.id}}>{{eachval.sname}}</option>
                    </select>
                    <app-show-errors [control]="myform.controls.inputState"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputCity">Select City</label>
                    <select class="form-control"
                      [ngClass]="myform.controls.inputCity.touched&&myform.controls.inputCity.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputCity">
                      <option *ngFor="let eachval of city_list" value={{eachval.id}}>{{eachval.cname}}</option>
                    </select>
                    <app-show-errors [control]="myform.controls.inputCity"></app-show-errors>
                  </div>
                  <div class="form-group">
                    <label for="inputAddress">Address</label>
                    <textarea class="form-control" rows="3" name="inputAddress"
                      [ngClass]="myform.controls.inputAddress.touched&&myform.controls.inputAddress.errors?'is-invalid form-control':'form-control'"
                      formControlName="inputAddress" placeholder="Enter Address"></textarea>
  
                    <app-show-errors [control]="myform.controls.inputAddress"></app-show-errors>
                  </div>
  
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                  <button type="submit" [disabled]="myform.invalid" class="btn btn-primary">Update Student Details</button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
          <!-- right column -->
          <div class="col-md-6">
  
          </div>
          <!--/.col (right) -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  
  </div>