import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BranchService } from 'src/app/shared/services/branch.service';
import { CitiesService } from 'src/app/shared/services/cities.service';
import { StatesService } from 'src/app/shared/services/states.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { ModelService } from 'src/app/webview/components/model/model.service';
import { CustomValidators } from 'src/app/webview/components/validators-error-handlers/custom-validators';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  myform:FormGroup;

  city_list:any = [];
  state_list:any = [];
  branch_list:any = [];
  
  sub_branch_list:any = [];
  selected_branch_id:any;

  constructor(private fb:FormBuilder,
              private router:Router,
              private _flashMessageService:FlashMessagesService,
              private modelservice:ModelService,
              private bservice:BranchService,
              private stateService:StatesService,
              private cityService:CitiesService,
              private userService:UsersService
              ) {

   }

  ngOnInit(): void {
     this.myform = this.fb.group({
      'inputFirstName':this.fb.control('',[Validators.required]),
      'inputLastName':this.fb.control('',[Validators.required]),
      'inputGender':this.fb.control('',[Validators.required]),
      'inputContact':this.fb.control('',[Validators.required]),
      'inputRole':this.fb.control('',[Validators.required]),
      'inputBranch':this.fb.control('',[Validators.required]),
      'inputSubBranch':this.fb.control(0,[Validators.required]),
      'inputEmail':this.fb.control('',[Validators.required,Validators.email]),
      'inputPassword':this.fb.control('',[Validators.required]),
      'inputCPassword':this.fb.control('',[Validators.required,AddUserComponent.matchValues('inputPassword')]),
      'inputState': this.fb.control('', [Validators.required]),
      'inputCity': this.fb.control('', [Validators.required]),
      'inputAddress': this.fb.control('', [Validators.required]),
    })
    this.bservice.getBranchLists()
                 .subscribe((res:any)=>{
                    this.branch_list = res.blist;
                 })
    this.stateService.getStatesList()
                    .subscribe((res:any)=>{
                      this.state_list = res.slist;
                    })

  }

  updateCityList(event){
    this.cityService.getStateBasedCityList(event)
                    .subscribe((res:any)=>{
                      this.city_list = res.clist;
                    })
  }

 

  public static matchValues(matchTo:string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
     }
  }

  updateSubBranchList(event){
    this.selected_branch_id = event;
    this.sub_branch_list = this.bservice.getSubBranchListsForBranch(this.selected_branch_id)
                                        .subscribe((res:any)=>{
                                          console.log(res);
                                          this.sub_branch_list = res.sblist;
                                        })
  }

  updateSubBranchSelectedValue(event){
   // this.selected_subbranch_id = event;
  }

  onFormSubmit(){
    console.log(this.myform.value);
    this.userService.addNewUser(this.myform.value)
                 .subscribe((resultdata)=>{
                  
                  this.modelservice.displayDialog('Alert Message', "New User Has Been Added");
                  this.router.navigate(['list-users']);
                },
                error=>{
                  console.log(error.error.message);
                  this._flashMessageService.grayOut(true);
                  this.modelservice.displayDialog('Alert Message', error.error.message);
                });
  }
  

}
