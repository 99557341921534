import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UsersService } from 'src/app/shared/services/users.service';



@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auths: UsersService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
   const isAuthStauts = this.auths.getAuthStatus();
   if (!isAuthStauts) {
       this.router.navigate(['/']);
   }
   return isAuthStauts;
  }
}
