<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Add New User</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Add New User</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
 </section>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- jquery validation -->
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Add New User</h3>
            </div>
            <!-- /.card-header -->
            <!-- form start -->
            <form id="userForm" method="POST" 
            [formGroup]="myform" (submit)="onFormSubmit()">
              <div class="card-body">
                <div class="form-group">
                    <label for="inputFirstName">FirstName</label>
                    <input type="text" 
                           name="inputFirstName" 
                           [ngClass]="myform.controls.inputFirstName.touched&&myform.controls.inputFirstName.errors?'is-invalid form-control':'form-control'" 
                           formControlName="inputFirstName"
                           placeholder="Enter FirstName">
                    <app-show-errors [control]="myform.controls.inputFirstName"></app-show-errors>
                </div>
                <div class="form-group">
                    <label for="inputLastName">LastName</label>
                    <input type="text" 
                           name="inputLastName" 
                           [ngClass]="myform.controls.inputLastName.touched&&myform.controls.inputLastName.errors?'is-invalid form-control':'form-control'" 
                           formControlName="inputLastName"
                           placeholder="Enter LastName">
                    <app-show-errors [control]="myform.controls.inputLastName"></app-show-errors>
                </div>
               
                <div class="form-group">
                    <label for="inputGender">Select Gender</label>
                    <select class="form-control"
                    [ngClass]="myform.controls.inputGender.touched&&myform.controls.inputGender.errors?'is-invalid form-control':'form-control'" 
                            formControlName="inputGender">
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                    <app-show-errors [control]="myform.controls.inputGender"></app-show-errors>
                </div>
                <div class="form-group">
                    <label for="inputContact">Contact No</label>
                    <input type="text" 
                           name="inputContact" 
                           [ngClass]="myform.controls.inputContact.touched&&myform.controls.inputContact.errors?'is-invalid form-control':'form-control'" 
                           formControlName="inputContact"
                           placeholder="Enter Contact">
                    <app-show-errors [control]="myform.controls.inputContact"></app-show-errors>
                </div>
                <div class="form-group">
                    <label for="inputRole">Select Role</label>
                    <select class="form-control"
                    [ngClass]="myform.controls.inputRole.touched&&myform.controls.inputRole.errors?'is-invalid form-control':'form-control'" 
                            formControlName="inputRole">
                      <option value="0">Administrator</option>
                      <option value="1">Sub-Admin</option>
                      <option value="2">User</option>
                      <option value="3">Sevajan</option>
                    </select>
                    <app-show-errors [control]="myform.controls.inputRole"></app-show-errors>
                </div>
                <div class="form-group">
                    <label for="inputBranch">Select Branch</label>
                    <select (ngModelChange)="updateSubBranchList($event)"
                    class="form-control"
                    [ngClass]="myform.controls.inputBranch.touched&&myform.controls.inputBranch.errors?'is-invalid form-control':'form-control'" 
                            formControlName="inputBranch">
                      <option *ngFor="let eachbranch of branch_list" value={{eachbranch.id}}>{{eachbranch.bname}}</option>
                    </select>
                    <app-show-errors [control]="myform.controls.inputBranch"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputSubBranch">Select SubBranch</label>
                  <select (ngModelChange)="updateSubBranchSelectedValue($event)"
                  class="form-control"
                  [ngClass]="myform.controls.inputSubBranch.touched&&myform.controls.inputSubBranch.errors?'is-invalid form-control':'form-control'" 
                          formControlName="inputSubBranch">
                          <option value="0">None</option>
                          <option *ngFor="let eachsbranch of sub_branch_list" value={{eachsbranch.id}}>{{eachsbranch.sbname}}</option>
                  </select>
                  <app-show-errors [control]="myform.controls.inputSubBranch"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputEmail">Email ID</label>
                  <input type="text" 
                         name="inputEmail" 
                         [ngClass]="myform.controls.inputEmail.touched&&myform.controls.inputEmail.errors?'is-invalid form-control':'form-control'" 
                         formControlName="inputEmail"
                         placeholder="Enter Email ID">
                  <app-show-errors [control]="myform.controls.inputEmail"></app-show-errors>
              </div>
                <div class="form-group">
                    <label for="inputPassword">Enter Password</label>
                    <input type="password" 
                           name="inputPassword" 
                           [ngClass]="myform.controls.inputPassword.touched&&myform.controls.inputPassword.errors?'is-invalid form-control':'form-control'" 
                           formControlName="inputPassword"
                           placeholder="Enter Password">
                    <app-show-errors [control]="myform.controls.inputPassword"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputSPassword">Confirm Password</label>
                  <input type="password" 
                         name="inputCPassword" 
                         [ngClass]="myform.controls.inputCPassword.touched&&myform.controls.inputCPassword.errors?'is-invalid form-control':'form-control'" 
                         formControlName="inputCPassword"
                         placeholder="Confirm Password">
                  <app-show-errors [control]="myform.controls.inputCPassword"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputState">Select State</label>
                  <select (ngModelChange)="updateCityList($event)"
                  class="form-control"
                  [ngClass]="myform.controls.inputState.touched&&myform.controls.inputState.errors?'is-invalid form-control':'form-control'" 
                          formControlName="inputState">
                    <option *ngFor="let eachval of state_list" value={{eachval.id}}>{{eachval.sname}}</option>
                  </select>
                  <app-show-errors [control]="myform.controls.inputState"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputCity">Select City</label>
                  <select
                  class="form-control"
                  [ngClass]="myform.controls.inputCity.touched&&myform.controls.inputCity.errors?'is-invalid form-control':'form-control'" 
                          formControlName="inputCity">
                    <option *ngFor="let eachval of city_list" value={{eachval.id}}>{{eachval.cname}}</option>
                  </select>
                  <app-show-errors [control]="myform.controls.inputCity"></app-show-errors>
                </div>
                <div class="form-group">
                  <label for="inputAddress">Address</label>
                  <textarea class="form-control" 
                            rows="3"
                            name="inputAddress"
                            [ngClass]="myform.controls.inputAddress.touched&&myform.controls.inputAddress.errors?'is-invalid form-control':'form-control'" 
                            formControlName="inputAddress"
                            placeholder="Enter Address"></textarea>
                 
                  <app-show-errors [control]="myform.controls.inputAddress"></app-show-errors>
              </div>
                
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <button type="submit" [disabled]="myform.invalid" class="btn btn-primary">Add User</button>
              </div>
            </form>
          </div>
          <!-- /.card -->
          </div>
        <!--/.col (left) -->
        <!-- right column -->
        <div class="col-md-6">

        </div>
        <!--/.col (right) -->
      </div>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </section>
  <!-- /.content -->

</div>